<template>
  <div class="yellow-bg">
    <div class="vimeo-wrapper">
      <iframe
        src="https://player.vimeo.com/video/488134138?background=1&autoplay=1&loop=1&byline=0&title=0"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      >
      </iframe>
    </div>
    <div class="h-screen flex justify-center items-center">
    <div class="mantti-logo">
        <img :src="require('@/assets/mantti-live-glow.png')" />
        <div class="content">
          <a href="https://www.levykauppax.fi/merch/mokoma/mantti_tv_live_esittaa_mokoma_tyopaikan_pikkujoulut_2020/#715487" target="_blank">
            <button class="btn-yellow py-2 px-4 mt-4 rounded">
              Osta lippu
            </button>
          </a>
          <router-link to="/live" v-if="eventInfo != null && eventInfo.loginEnabled">
            <button v-if="isUser" class="btn-yellow btn-green py-2 px-4 mt-4 ml-4 rounded">
              <span>Jatka katselua</span>
            </button>
            <button v-else class="btn-yellow py-2 px-4 mt-4 ml-4 rounded">
              <span>Aloita katselu</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {auth, db} from "@/firebase";

export default {
  name: "Home",
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("@/assets/mantti.png")})`,
      },
      eventInfo: null
    };
  },
  firestore: {
    eventInfo: db.collection('events').doc('1qwoKJYKBAq0az4gWL4h')
  },
  computed: {
    isUser: function (){
      if( auth.currentUser ) {
        // console.debug( auth.currentUser )
        return true
      }
      return false
    }
  },
  mounted() {
    // console.debug(this.$Tawk)
  }
};
</script>

<style lang="scss">
.content {
  margin-top: 3em;
  color: white;
  font-weight: bold;
  h2 {
    margin: 1em 0 0 0;
    font-size: 1.6em;
  }
}


</style>
