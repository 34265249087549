import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDrBai9wWFkKsgYtbHzE26G8LcV6FCO66U",
  authDomain: "sakaralive-666.firebaseapp.com",
  databaseURL: "https://sakaralive-666.firebaseio.com",
  projectId: "sakaralive-666",
  storageBucket: "sakaralive-666.appspot.com",
  messagingSenderId: "1072135443252",
  appId: "1:1072135443252:web:bdf3b25114a41ad129cb61",
  measurementId: "G-82YPMB6M36",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.app().functions("europe-west1");

const streamCollection = db.collection("stream");

// Always run functions/emulate before developing.
//if (process.env.NODE_ENV !== "production")
//  firebase.functions().useFunctionsEmulator("http://localhost:5001");

const authenticateWithToken = async (token) => {
  try {
    await auth.signInWithCustomToken(token);
  } catch (err) {
    console.error(err);
  }
};

const checkCode = functions.httpsCallable("checkCode");
const prepareForLogout = functions.httpsCallable("prepareForLogout");

export {
  db,
  auth,
  streamCollection,
  checkCode,
  prepareForLogout,
  authenticateWithToken,
};
