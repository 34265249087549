<template>
  <div class="grid grid-cols-12 wrap">
    <div class="col-span-12 top-bar" v-if="eventInfo != null && eventInfo.showMessage">
      <p class="message" v-html="eventInfo.message"></p>
    </div>
    <div class="md:col-span-9 col-span-12">
      <div style="padding: 56.25% 0 0 0; position: relative" >
        <iframe v-if="activeStreams.length"
          :src="activeStreams[0].vimeoURL"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
    <div class="md:col-span-3 col-span-12" v-if="activeStreams.length">
      <iframe
        :src="activeStreams[0].chatURL"
        width="100%"
        height="100%"
        frameborder="0"
        style="min-height: 280px"
      />
    </div>
    <div class="md:col-span-9 col-span-12 btn-logout text-center">
      <button class="btn-yellow my-5 py-2 px-4 rounded" @click="logOut()">Lopeta katselu</button>
      <p class="text-white text-xs text-gray-600 mb-4">
        Lopeta katselu jos haluat jatkaa katselua toisella laitteella.
      </p>
    </div>
  </div>
</template>

<script>
import { auth, db, prepareForLogout } from "@/firebase";

export default {
  name: "Live",
  data() {
    return {
      activeStreams: [],
      eventInfo: null
    };
  },
  firestore: {
    activeStreams: db.collection('streams').where( "active", "==", true ),
    eventInfo: db.collection('events').doc('1qwoKJYKBAq0az4gWL4h')
  },
  methods: {
    logOut: async function () {
      const isReady = await prepareForLogout();
      if (isReady.error) {
        console.error("Something unexpected happened");
      }
      await auth.signOut();
      await this.$router.push("/");
    },
  },
  computed: {
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.wrap {
  background-color: black;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-bar{
  top:0;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 1em;
  background-color: rgba(0,0,0,0.7);
  color: #ff0000;
  .message{
    a {
      color: #fdff2d;
      // text-decoration: underline;
    }
  }
}
</style>
